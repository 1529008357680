<template>
  <div class="stops-info">
    <div class="stops-info__content">
      <!-- Residential Validation Message -->
      <div class="message-error">
        <span v-if="showErrorResidentialMessage"> * Please change the address </span>
      </div>
      <b-tabs card no-fade>
        <b-tab :title="selectedMode">
          <b-overlay
            :show="loading"
            rounded="sm"
            class="stops-info__overlay"
            variant="light"
            blur="10px"
          >
            <span
              v-if="!loading"
              class="go-back mb-4 ml-4 d-flex"
              @click="changeView('CarriersInfo')"
            >
              <i class="ion ion-chevron-back-outline"></i>
              <p class="ml-2">{{ $t("carriers.goBack") }}</p>
            </span>
            <div class="stops-info__load-name" v-if="!loading">
              <span> {{ $t("carriers.quoteNumber") }} {{ chosenLoad.name }} </span>
            </div>
            <div class="stops-info-card">
              <div class="form-container mt-3">
                <div class="form-container__pickup" v-show="currentStep === 'pickup' && selectedCarrier">
                  <StopsShippingInfo
                    v-if="!loading"
                    @getStopData="getStopData"
                    @getReferences="getReferences"
                    v-model="stopsPickup"
                    :mode="'pickup'"
                    :locationInformation="location.pickup"
                    :contactInformation="contact.pickup"
                    :limitIntructionsCharacters="limitIntructionsCharacters"
                    :shippingInformation="shippingInfo.pickup"
                    :selectedCarrier="selectedCarrier"
                    ref="pickupStop"
                  />
                  <StopsShippingInfoSkeleton v-else />
                </div>
                <div
                  class="form-container__delivery"
                  v-show="(screenWidth && screenWidth > 800) || currentStep === 'delivery' && selectedCarrier"
                >
                  <StopsShippingInfo
                    v-if="!loading"
                    @getStopData="getStopData"
                    @getReferences="getReferences"
                    @accountInformation="accountInformation"
                    v-model="stopsDelivery"
                    :mode="'delivery'"
                    :locationInformation="location.delivery"
                    :contactInformation="contact.delivery"
                    :shippingInformation="shippingInfo.delivery"
                    :limitIntructionsCharacters="limitIntructionsCharacters"
                    :transitTime="transitTime"
                    :selectedCarrier="selectedCarrier"
                    ref="deliveryStop"
                  />
                  <StopsShippingInfoSkeleton v-else />
                </div>
              </div>
              <div v-if="screenWidth >= 800 || currentStep === 'lineItems'">
                <LoadDetail
                  @getReferences="getReferences"
                  :chosenLoad="chosenLoad"
                  :renderView="'Desktop'"
                  :persistedInformation="references.loadInformation"
                  :screenWidth="screenWidth"
                  ref="loadDetail"
                />
              </div>
              <hr class="divider" v-if="screenWidth >= 800" />
              <LineItemsContainer
                v-if="selectedCarrier"
                :chosenLoad="chosenLoad"
                :screenWidth="screenWidth"
                :loading="loading"
                :isHazmat="isHazmat"
                :currentStep="currentStep"
                :selectedCarrier="selectedCarrier"
                ref="lineItemsContainer"
              />
              <div class="continue-button-container" v-if="chosenLoad">
                <b-overlay
                  class="continue-button-overlay"
                  :show="updatingRequestInProcess"
                  rounded="sm"
                >
                  <button
                    class="button-tender"
                    v-if="screenWidth && screenWidth >= 800"
                    @click="updateStops()"
                  >
                    {{ $t("stopsInfo.continue") }}
                  </button>
                  <button
                    class="button-tender"
                    v-show="
                      (currentStep === 'delivery' || currentStep === 'lineItems') &&
                      screenWidth &&
                      screenWidth < 800
                    "
                    @click="reponsiveFlowBack(currentStep === 'delivery' ? 'pickup' : 'delivery')"
                  >
                    {{ currentStep === "delivery" ? $t("stopsInfo.back") : "" }}
                    {{ currentStep === "lineItems" ? $t("stopsInfo.back") : "" }}
                  </button>
                  <button
                    class="button-tender"
                    v-show="
                      (currentStep === 'delivery' && screenWidth && screenWidth < 800) ||
                      (currentStep && screenWidth && screenWidth < 800)
                    "
                    @click="updateStopsResponsive(currentStep)"
                  >
                    {{ currentStep === "delivery" ? $t("stopsInfo.next") : "" }}
                    {{ currentStep === "pickup" ? $t("stopsInfo.next") : "" }}
                    {{ currentStep === "lineItems" ? $t("stopsInfo.continue") : "" }}
                  </button>
                </b-overlay>
              </div>
            </div>
            <template #overlay>
              <div class="loading" v-show="loading">
                <b-icon
                  icon="truck"
                  font-scale="3"
                  animation="cylon"
                  class="loading__text"
                ></b-icon>
                <p class="loading__text">{{ $t("loading") }}...</p>
              </div>
            </template>
          </b-overlay>
        </b-tab>
      </b-tabs>
    </div>
    <b-modal
      centered
      size="lg"
      v-model="tenderConfirmationModal"
      ref="confirmation-modal"
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      @hide="handleModalClosing"
    >
      <TenderConfirmation
        v-if="chosenLoad"
        v-model="tenderConfirmationModal"
        :selectedCarrier="selectedCarrier"
        :chosenLoad="chosenLoad"
        :lineItems="chosenLoad.line_items"
        :pickupStopInfo="stopsPickup"
        :carriersAndTrucks="carriersAndTrucks"
        @changeLoadingTender="changeLoadingTender"
      />
    </b-modal>
    <b-overlay
      :show="loadingTender || creatingTender || requestInProgress"
      rounded="sm"
      z-index="1200"
      no-wrap
      fixed
    >
      <template #overlay>
        <div class="loading-overlay-tender">
          <b-icon icon="truck" animation="cylon" font-scale="4"></b-icon>
          <FadeAnimationTextArray :textArray="loadingTextArray" :duration="6000" />
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import { mapGetters } from "vuex";
import onReSize from "@/Extend/onResize";
import dayjs from "dayjs";
import StopsShippingInfo from "./components/StopInformation.vue";
import StopsShippingInfoSkeleton from "./components/StopInformationSkeleton.vue";
import TenderConfirmation from "./components/TenderConfirmation.vue";
import LoadDetail from "./components/LoadDetail.vue";
import FadeAnimationTextArray from "../../../components/FadeAnimationTextArray.vue";
import LoadingTextArray from "../../../utils/LoadingTextArray";
import LineItemsContainer from "./components/lineItems/LineItemsContainer.vue";
import TenderIndexedDB from "../../../Extend/TenderIndexedDB";
import modes from "../modes";

export default {
  name: "StopsResume",
  mixins: [onReSize, TenderIndexedDB],
  components: {
    StopsShippingInfo,
    StopsShippingInfoSkeleton,
    TenderConfirmation,
    LoadDetail,
    FadeAnimationTextArray,
    LineItemsContainer,
  },
  data() {
    return {
      loadingTender: false,
      creatingTender: false,
      requestInProgress: false,
      tenderConfirmationModal: false,
      stopsPickup: null,
      stopsDelivery: null,
      chosenLoad: null,
      loading: false,
      description: "",
      nmfc_number: "",
      stops: null,
      shippingInfo: {
        pickup: null,
        delivery: null,
      },
      contact: {
        pickup: null,
        delivery: null,
      },
      location: {
        pickup: null,
        delivery: null,
      },
      currentStep: "pickup",
      mode: null,
      carriersAndTrucks: null,
      transitTime: null,
      hazmatError: false,
      isHazmat: false,
      indexedDBInstance: null,
      references: {
        pickup: null,
        delivery: null,
        loadInformation: null,
      },
      loadingTextArray: LoadingTextArray.tender,
      lineItemsReference: null,
      selectedCarrier: {},
      limitIntructionsCharacters: null,
      /* Residential Locations variables */
      isResidentialDeliveryAccesorialFound: false,
      showErrorResidentialMessage: false,
      deliveryfieldValidation: false,
    };
  },
  watch: {
    loading() {
      if (this.loading) {
        window.onbeforeunload = () => {};
      } else {
        window.onbeforeunload = null;
      }
    },
    screenWidth() {
      if (this.screenWidth > 800) {
        this.currentStep = "pickup";
      }
    },
    selectedCarrier: {
      deep: true,
      handler() {
        if (this.selectedCarrier) {
          this.analizeCarrierToLimitIntructions();
        }
      },
    },
  },
  computed: {
    selectedMode() {
      // eslint-disable-next-line operator-linebreak
      const isMultiservice =
        "multiservice" in this.chosenLoad
          ? this.chosenLoad.multiservice
          : this.chosenLoad.load.multiservice;
      if (isMultiservice) {
        return "ALL OPTIONS";
      }
      return this.chosenLoad.mode_name.toUpperCase();
    },
    ...mapGetters({
      updatingRequestInProcess: "load/tender/getRequestInProcess",
    }),
  },
  async created() {
    this.loading = true;
    this.$store.commit("load/tender/setRequestInProcess", false);
    const { loadId } = this.$route.params;
    this.chosenLoad = this.$store.getters["load/getChosenLoad"];
    this.loadIsHazmat();
    this.getLoadCarrierSelectedInfo();
    await this.getCarrierById();
    if (!this.chosenLoad && loadId) {
      this.chosenLoad = await this.$store.dispatch("load/getLoadById", loadId);
    }
    this.distributeLoadInfoByStops();
    const promises = [
      this.getSalesforceAccounts(),
      this.$store.dispatch("carriers/getAllOptionsCarriers", loadId),
      this.$store.dispatch("carriersAlert/getAllCarriersAlert"),
    ];
    await Promise.all(promises).then((values) => {
      this.carriersAndTrucks = values[1];
    });
    await this.checkLoadIdInIndexedDB(
      "quoteBook",
      "tenderReferences",
      this.$route.params.loadId,
      this.references
    );
    this.loadIsHazmat();
    this.getTransitTime();
    this.loading = false;
  },
  beforeDestroy() {
    this.$store.commit("load/resetGroundShipment");
  },
  methods: {
    /* Residential Locations */
    async accountInformation(data) {
      this.isResidentialDeliveryAccesorialFound = this.findAccesorialByName("Residential Delivery");
      if (
        this.selectedCarrier.source === "easy-post" &&
        data !== null &&
        !this.isResidentialDeliveryAccesorialFound
      ) {
        if (data.account_id !== undefined || data.id !== undefined) {
          this.verifyResidentialStopByAccount(data.id_salesforce || data.id);
        }
        return;
      }
      this.deliveryfieldValidation = false;
      this.showErrorResidentialMessage = false;
    },
    async verifyResidentialStopByAccount(id) {
      const response = await this.$store.dispatch("address/verifyStopByAccount", id);
      if (response.residential) {
        this.showErrorResidentialMessage = true;
        this.deliveryfieldValidation = true;
        this.swal({
          title:
            "This location is residential, therefore, in order to proceed, you must include the 'Residential' accessorial",
          icon: "warning",
          buttons: {
            requote: {
              text: "Requote with accessorial",
              value: "requote",
            },
            changeLocation: {
              text: "Change location",
              value: "changeLocation",
            },
          },
          dangerMode: false,
        }).then((value) => {
          switch (value) {
            case "requote":
              this.addResidentialDeliveryAccessorial();
              break;
            case "changeLocation":
              this.$refs.deliveryStop.cancelSelectContact(true);
              break;
            default:
              this.$refs.deliveryStop.cancelSelectContact(true);
              break;
          }
        });
      }
    },
    async addResidentialDeliveryAccessorial() {
      const idLoad = this.$route.params.loadId;
      const response = await this.$store.dispatch("load/updateResidentialAccessorial", idLoad);
      if (response.status === 200) {
        this.addDeliveryAccessorialVuex(idLoad, response.data.id);
        this.$router.push({
          name: "CarriersInfo",
          params: {
            loadId: idLoad,
            socket: true,
          },
        });
      }
    },
    findAccesorialByName(name) {
      const getAccessorials = this.$store.getters["load/getChosenLoad"];
      return getAccessorials.accessorials.some((item) => item.name === name);
    },
    addDeliveryAccessorialVuex(idLoad, idResponse) {
      const newDeliveryAccessorial = {
        accessorial_id: 20,
        accessorial_type: "delivery",
        description: null,
        id: idResponse,
        id_load: idLoad,
        name: "Residential Delivery",
        order: null,
        related_name: null,
        tms_code: {
          accessorialId: "a011I00000325JAQAY",
          accessorialName: "Residential Delivery",
          stopNumber: null,
        },
      };
      this.$store.commit("load/modifyChosenLoadSpecificData", {
        key: "accessorials",
        value: [newDeliveryAccessorial],
      });
    },
    /* End Residential Locations */
    distributeLoadInfoByStops() {
      Object.keys(this.shippingInfo).forEach((key) => {
        const type = key === "pickup" ? "is_pickup" : "is_dropoff";
        this.shippingInfo[key] = this.chosenLoad.stops.find((element) => element[type] === true);
        if (this.shippingInfo[key].contact_info_id) {
          this.contact[key] = this.chosenLoad.contacts.find(
            (element) => element.id === this.shippingInfo[key].contact_info_id
          );
        }
        if (this.shippingInfo[key].location_id) {
          this.location[key] = this.chosenLoad.accounts.find(
            (element) => element.id === this.shippingInfo[key].location_id
          );
        }
      });
    },
    async getSalesforceAccounts() {
      if (this.$options.filters.checkPermission("get:salesforce-accounts")) {
        const dataSalesforceAccounts = {
          params: {
            // ONLY FOR TEST ACCOUNT
            account_id: process.env.VUE_APP_ACCOUNT_ID_TEST,
            skip: 0,
            limit: 1000,
          },
        };
        await this.$store.dispatch("load/stops/getSalesforceAccounts", dataSalesforceAccounts);
      }
    },
    changeView(routeName) {
      this.$store.commit("carriers/resetCarriersList");
      this.$router.push({
        name: routeName,
        params: {
          quoteType: "all-options",
          loadId: this.$route.params.loadId,
        },
      });
    },
    updateStopsendpointconsumption() {
      if (this.$options.filters.checkPermission("patch:stop")) {
        this.$store.commit("load/tender/setRequestInProcess", true);
        const loadInformation = this.$refs.loadDetail.updateLoadInformation();
        const foundHazmatItem = this.$refs.lineItemsContainer.findDefaultHazmatItem();
        const uploadStopsPromises = [foundHazmatItem];
        if (loadInformation) {
          uploadStopsPromises.push(loadInformation);
        }
        this.chosenLoad.stops.forEach((stop) => {
          let matchedStop;
          let stopType;
          if (this.stopsPickup.id === stop.id) {
            matchedStop = this.stopsPickup;
            stopType = "stopsPickup";
          } else if (this.stopsDelivery.id === stop.id) {
            matchedStop = this.stopsDelivery;
            stopType = "stopsDelivery";
          }
          const payload = {
            load_id: this.$route.params.loadId,
            stop_id: stop.id,
            body: {
              expected_date: dayjs(matchedStop.shippingData.date).format("YYYY-MM-DD"),
              shipping_receiving_hours: `${matchedStop.shippingData.firstHour}-${matchedStop.shippingData.secondHour}`,
              references: matchedStop.shippingData.references,
              instructions: matchedStop.shippingData.instructions,
            },
          };
          const newDataValidation = this.validateIfThereAreNewData(
            payload.body,
            this.$store.getters["load/tender/getStop"](stopType)
          );
          this.$store.commit("load/tender/setStopObject", {
            stopType,
            body: JSON.parse(JSON.stringify(payload.body)),
          });
          if (newDataValidation) {
            uploadStopsPromises.push(this.$store.dispatch("load/stops/updateStop", payload));
          }
        });
        return uploadStopsPromises;
      }
    },
    async updateStops() {
      const fieldsValidationPickup = this.$refs.pickupStop.stopFieldsValidation();
      const fieldsValidationDelivery = this.$refs.deliveryStop.stopFieldsValidation();
      const fieldsValidationLineItems =
        await this.$refs.lineItemsContainer.validateLineItemsReference();
      let ftlLimitsValidation = true;
      if (
        this.chosenLoad.mode_id === modes.ftl.modeId ||
        this.chosenLoad.mode_id === modes.reefer.modeId
      ) {
        ftlLimitsValidation = await this.$refs.lineItemsContainer.validateFTLLimits();
      }
      if (!ftlLimitsValidation) {
        this.creatingLoad = false;
        this.notifyWeightLimit();
      }
      /* Another validation on residency is also added. deliveryfieldValidation */
      if (
        fieldsValidationPickup &&
        fieldsValidationDelivery &&
        fieldsValidationLineItems &&
        ftlLimitsValidation &&
        !this.deliveryfieldValidation
      ) {
        this.$store.commit("load/tender/setRequestInProcess", true);
        const lineItemsRequests = this.$refs.lineItemsContainer.updateLoadLineItems();
        const loadRequests = this.updateStopsendpointconsumption();
        this.openConfirmationModal();
        this.loadPromisesHandler([lineItemsRequests, loadRequests].flat());
      }
    },
    async loadPromisesHandler(requests) {
      await Promise.all(requests).then(() => {
        this.$store.commit("load/tender/setRequestInProcess", false);
      });
    },
    openConfirmationModal() {
      this.tenderConfirmationModal = true;
    },
    async updateStopsResponsive(currentStep) {
      if (this.currentStep === "pickup") {
        const fieldsValidationPickup = this.$refs.pickupStop.stopFieldsValidation();
        if (!fieldsValidationPickup) {
          return;
        }
        this.currentStep = "delivery";
      } else if (this.currentStep === "delivery") {
        const fieldsValidationDelivery = this.$refs.deliveryStop.stopFieldsValidation();
        if (!fieldsValidationDelivery) {
          return;
        }
        this.currentStep = "lineItems";
      }
      if (currentStep === "lineItems") {
        const fieldsValidationLineItems =
          await this.$refs.lineItemsContainer.validateLineItemsReference();
        let ftlLimitsValidation = true;
        if (
          this.chosenLoad.mode_id === modes.ftl.modeId ||
          this.chosenLoad.mode_id === modes.reefer.modeId
        ) {
          ftlLimitsValidation = await this.$refs.lineItemsContainer.validateFTLLimits();
        }
        if (!ftlLimitsValidation) {
          this.creatingLoad = false;
          this.notifyWeightLimit();
        }
        if (fieldsValidationLineItems && ftlLimitsValidation) {
          const lineItemsRequests = this.$refs.lineItemsContainer.updateLoadLineItems();
          const loadRequests = this.updateStopsendpointconsumption();
          this.openConfirmationModal();
          this.loadPromisesHandler([lineItemsRequests, loadRequests].flat());
        }
      }
    },
    getStopData(data, mode) {
      if (mode === "pickup") {
        this.stopsPickup = data;
      } else {
        this.stopsDelivery = data;
      }
    },
    reponsiveFlowBack(data) {
      this.currentStep = data;
    },
    async tender() {
      const data = {
        id: this.$route.params.loadId,
        body: {
          carrier_id: this.$route.params.carrierId,
          insurance_required: this.$route.query.insurance === 'true',
        },
      };
      await this.$store.dispatch("load/tender/createTender", data);
    },
    getTransitTime() {
      const param = this.$route.params.carrierId;
      const id = "_id";
      if (param && id) {
        const carrier = this.carriersAndTrucks.carriers.find((element) => element[id] === param);
        this.transitTime = carrier.transitTime ? carrier.transitTime.toString() : "0";
      }
    },
    loadIsHazmat() {
      this.isHazmat = this.chosenLoad.accessorials.some(
        (item) => item.name.toLowerCase() === "hazmat"
      );
    },
    validateIfThereAreNewData(newInfo, oldInfo) {
      let newInfoValidation = false;
      if (!oldInfo) {
        newInfoValidation = true;
      } else {
        Object.keys(newInfo).forEach((key) => {
          if (newInfo[key] !== oldInfo[key]) {
            newInfoValidation = true;
          }
        });
      }
      return newInfoValidation;
    },
    handleModalClosing() {
      this.$store.commit("triggerAbortController");
    },
    getReferences(data) {
      this.references[data.mode] = { ...data };
      this.updateCollectionInIndexedDB(
        "tenderReferences",
        this.$route.params.loadId,
        this.references
      );
    },
    retrieveInfoFromIndexedDBCollection(references) {
      Object.keys(references).forEach((key) => {
        if (references[key] && key in this.shippingInfo) {
          this.shippingInfo[key].references = references[key].references;
        }
        this.references[key] = references[key];
      });
    },
    changeLoadingTender(data) {
      this.$data[data.loadingType] = data.value;
    },
    getLoadCarrierSelectedInfo() {
      const allCarriers = this.$store.getters["carriers/getCarriers"];
      this.selectedCarrier = allCarriers.find((carrier) => {
        const { _id } = carrier;
        return _id === this.$route.params.carrierId;
      });
    },
    analizeCarrierToLimitIntructions() {
      if (
        this.selectedCarrier.carrier === "UPS Ground (EasyPost)" ||
        this.selectedCarrier.carrier === "UPS 3DaySelect (EasyPost)"
      ) {
        this.limitIntructionsCharacters = true;
        return;
      }
      this.limitIntructionsCharacters = false;
    },
    notifyWeightLimit() {
      this.swal({
        title: `Weight limit exceeded`,
        text: "Total weight should not exceed the load initial weight.",
        icon: "error",
        dangerMode: true,
      });
    },
    async getCarrierById() {
      const response = await this.$store.dispatch(
        "carriers/getCarrierById",
        this.$route.params.carrierId
      );
      this.selectedCarrier = response;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/components/Modal.scss";
@import "../../../assets/scss/components/StopsResume.scss";

.divider {
  width: 90%;
  margin-top: 10px;
  border-bottom: 1px solid $color-border-container;
}

.loading {
  svg,
  p {
    color: $color-primary-company;
    font-weight: bold;
  }
}
.loading-overlay-tender {
  max-width: 500px;
  svg,
  p {
    color: $color-primary-company;
  }
}
::v-deep .bg-light {
  background-color: $color-gray-light !important;
}
///****Tabs styles****\\\\
::v-deep .card-header {
  background-color: $color-white;
  border-bottom: 0px;
  padding: 12px 9.8px;
}
::v-deep .card-body {
  background: $color-gray-light;
  border: 1px solid $color-border-container;
  border-radius: 0px 10px 10px 10px;
  font-family: $font-family-portal;
  padding: 25px 0;
}
::v-deep .nav-tabs .nav-link {
  background-color: $color-gray-dark;
  color: $color-white;
  font-family: $font-family-portal;
  @include font-small-information;
  font-weight: bold;
  border: 1px solid $color-border-container;
  border-left: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 15px 30px;
  cursor: default;
}
::v-deep .nav-tabs .nav-link.active {
  color: $color-primary-company;
  font-family: $font-family-portal;
  @include font-small-information;
  font-weight: bold;
  background-color: $color-gray-light;
  border: 1px solid $color-border-container;
  border-bottom: 1px solid $color-gray-light;
  padding: 15px 30px;
}
::v-deep .nav-tabs > li {
  &:first-child > a {
    border-top-left-radius: 20px;
  }
  &:last-child > a {
    border-top-right-radius: 20px;
  }
}
// Date and time picker styles
::v-deep .el-input {
  width: 100%;
  border-radius: 20px;
}
::v-deep .el-input__icon {
  line-height: unset;
  color: $color-border-container;
}
::v-deep .el-collapse-item__wrap {
  background: transparent;
}
::v-deep .el-input__inner {
  height: 35px;
  border-radius: 12px;
  border: 1px solid $color-border-container;
  line-height: unset;
  text-align: center;
}
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 0;
}
::v-deep {
  .el-collapse-item__header {
    background-color: $color-gray-light;
    color: $color-primary-company;
    font-size: 0.95rem;
  }
  .el-collapse-item__content {
    background-color: $color-gray-light;
    color: $color-primary-company;
    font-size: 14px;
    padding-bottom: 0;
  }
}
::v-deep .b-table-stacked-lg > tbody > tr {
  @media (max-width: 991.98px) {
    border-radius: 5px;
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.09), 0 1px 5px rgba(0, 0, 0, 0.09);
    margin: 20px 10px;
    padding: 10px 0px 10px 0px;
    word-wrap: break-word;
  }
}

::v-deep .position-absolute.bg-light.rounded-sm {
  opacity: 0.97 !important;
}
/* Residential Validations Styles*/
.message-error {
  display: flex;
  position: static;
  color: $color-error;
  margin-left: 3px;
  font-size: 0.8rem;
  position: absolute;
  top: 281px;
  left: 65%;
}
</style>
